import React, { useState, MouseEvent, useEffect } from 'react';
import '../styles/HelpModal.css';

interface HelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const HelpModal: React.FC<HelpModalProps> = ({ isOpen, onClose }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (isOpen) {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      setPosition({
        x: (windowWidth - 800) / 2, // 800px is modal width
        y: (windowHeight - 600) / 2 // approximate modal height
      });
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleMouseDown = (e: MouseEvent) => {
    setIsDragging(true);
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
    setDragOffset({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    });
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragOffset.x,
        y: e.clientY - dragOffset.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className="help-modal-overlay">
      <div 
        className="help-modal"
        style={{
          left: position.x,
          top: position.y,
          transform: 'none'
        }}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div className="help-modal-header" onMouseDown={handleMouseDown}>
          <h2>Demo Tenant Management Guide</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="help-content">
          <h3>Overview</h3>
          <p>This tool allows users to create and delete tenants on-demand.  Tenants are created in the <a href="https://demoprod.otprivacy.com" target="_blank">https://demoprod.otprivacy.com</a> environment and will persist until they are deleted by the tenant owner, an administrator, or after 30 days of inactivity.  Each user can have up to 5 tenants at a time.</p>
          
          <h3>Creating a Tenant</h3>
          <ul>
            <li>Enter the tenant name.  The name must be unique.</li>
            <li>Select the tenant type (English or QRA)</li>
            <li>Click "Create Tenant" to submit</li>
            <li>A row containing tenant details will be added to the table</li>
            <li>The "Check Status" button can be used to monitor the tenant creation process.  Initially, the Tenant GUID will be blank and Tenant Status will be "Pending creation".  After a few minutes, the Tenant GUID will be populated and Tenant Status will be updated to "importing_data" when clicking "Check Status".  This indicates that tenant creation is in progress.</li>
            <li>Once the tenant is created, Tenant Status will be updated to "completed_creation" and the "Check Status" button will be removed.  <u>Tenant creation can take 30-45 minutes, or longer if there is a large volume of requests to create a tenant.</u></li>
            <li>The tenant can be accessed at <a href="https://demoprod.otprivacy.com" target="_blank">https://demoprod.otprivacy.com</a> using your otprivacy.com email address and password.  If you have mutliple demo tenants, you will need to use the Switch Tenant functionality in the platform to access the new tenant.</li>
          </ul>

          <h3>Delete a Tenant</h3>
          <ul>
            <li>Click the delete button associated to the tenant to be deleted</li>
            <li>Confirm that the tenant should be deleted in the confirmation dialog</li>
            <li>After confirming deletion, the tenant will be removed from the listing.  Deletion from the demoprod.otprivacy.com environment may take a few minutes.</li>
            <li>Deleted tenants cannot be recovered.</li>
          </ul>

          <h3>Support</h3>
          <p>If you have any questions or need assistance, please contact <a href="mailto:demotechsupport@onetrust.com">demotechsupport@onetrust.com</a>.</p>

        </div>
        <div className="help-modal-buttons">
          <button className="btn btn-secondary" onClick={onClose}>Close</button>
        </div>
        <br /><br />
      </div>
    </div>
  );
};

export default HelpModal;