import React, { memo, useState } from 'react';
import { AdminTableItem } from '../services/adminService';

interface AdminTableProps {
  items: AdminTableItem[];
  onCheckStatus: (id: string, tenantType: string, version: string) => void;
}

const AdminTable: React.FC<AdminTableProps> = memo(({ items, onCheckStatus }) => {
  const [loadingId, setLoadingId] = useState<string | null>(null);

  const handleCheckStatus = async (id: string, tenantType: string, version: string) => {
    setLoadingId(id);
    await onCheckStatus(id, tenantType, version);
    setLoadingId(null);
  };

  return (
    <div className="table-container">
      <table className="tenant-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Tenant Type</th>
            <th>Requestor</th>
            <th>Status</th>
            <th>Version</th>
            <th>Date Created</th>
            <th>Date Completed</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.tenantType}</td>
              <td>{item.requestor}</td>
              <td>{item.backupStatus}</td>
              <td>{item.backupVersion}</td>
              <td>{item.createDate}</td>
              <td>{item.completeDate}</td>
              <td>
                  <button 
                    className="btn" 
                    onClick={() => handleCheckStatus(item.id, item.tenantType, item.backupVersion)}
                    disabled={loadingId === item.id}
                  >
                    {loadingId === item.id ? (
                      <div className="spinner-container">
                        <div className="spinner"></div>
                        Checking...
                      </div>
                    ) : (
                      'Check Status'
                    )}
                  </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default AdminTable;
