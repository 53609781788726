import React, { useState } from 'react';
import { Tenant } from '../types/Tenant'
import '../styles/index.css'

interface TenantTableProps {
  tenants: Tenant[];
  onCheckStatus: (id: string) => void;
  onDeleteTenant: (tenant: Tenant) => void;
  isLoading: boolean;
}

const TenantTable: React.FC<TenantTableProps> = ({ tenants, onCheckStatus, onDeleteTenant, isLoading }) => {
const [loadingId, setLoadingId] = useState<string | null>(null);

  const handleCheckStatus = async (id: string) => {
    console.log("In handleCheckStatus");
    setLoadingId(id);
    await onCheckStatus(id);
    setLoadingId(null);
  };

  if (isLoading) {
    return (
      <div className="tenant-table-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="table-container">
      <h2>Tenants</h2>
      <table className="tenant-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Requestor Email</th>
            <th>Tenant Type</th>
            <th>Tenant GUID</th>
            <th>Tenant Status</th>
            <th>Date Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tenants.map((tenant) => (
            <tr key={tenant.Id}>
              <td title={tenant.tenantName}>{tenant.tenantName}</td>
              <td title={tenant.tenantOwner}>{tenant.tenantOwner}</td>
              <td title={tenant.tenantType}>{tenant.tenantType}</td>
              <td title={tenant.tenantGUID}>{tenant.tenantGUID}</td>
              <td title={tenant.tenantStatus}>{tenant.tenantStatus}</td>
              <td title={tenant.createDate}>{tenant.createDate}</td>
              <td>
                {tenant.tenantStatus !== 'completed_creation' && (
                  <button 
                    className="btn" 
                    onClick={() => handleCheckStatus(tenant.Id)} 
                    disabled={loadingId === tenant.Id}
                  >
                    {loadingId === tenant.Id ? (
                      <div className="spinner-container">
                        <div className="spinner"></div>
                        Checking...
                      </div>
                    ) : (
                      'Check Status'
                    )}
                  </button>
                )}
                <button 
                  className="btn btn-danger" 
                  onClick={() => onDeleteTenant(tenant)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TenantTable;
