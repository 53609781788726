import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch';
import { useMemo } from 'react';

export interface AdminTableItem {
  id: string;
  tenantType: string;
  requestor: string;
  backupStatus: string;
  backupVersion: string;
  createDate: string;
  completeDate: string;
}

const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3005';

export const useAdminService = () => {
  const authenticatedFetch = useAuthenticatedFetch();

  return useMemo(() => ({
    getUpgrades: async (): Promise<AdminTableItem[]> => {
      const response = await authenticatedFetch(`${API_URL}/admin/getUpgrades`, {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('Failed to fetch upgrades');
      }
      return response.json();
    },

    submitBackupUpgrade: async (tenantType: string, version: string): Promise<AdminTableItem> => {
      const response = await authenticatedFetch(`${API_URL}/admin/upgrade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tenantType, version }),
      });
      if (!response.ok) {
        throw new Error('Failed to submit backup/upgrade request');
      }
      return response.json();
    },

    checkStatus: async (id: string, tenantType: string, version: string): Promise<AdminTableItem> => {
      const response = await authenticatedFetch(`${API_URL}/admin/upgrade/status/${id}/${tenantType}/${version}`, {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('Failed to check status');
      }
      return response.json();
    },
  }), [authenticatedFetch]);
};