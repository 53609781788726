import React, { useState } from 'react';
import { Tenant, TenantFormData } from '../types/Tenant'
import '../styles/index.css'
import { useMsal } from "@azure/msal-react";

//type TenantFormData = Pick<Tenant, 'tenantName' | 'requestorEmail' | 'tenantType'>;

interface TenantFormProps {
  onSubmit: (tenant: TenantFormData) => void;
}

const TenantForm: React.FC<TenantFormProps> = ({ onSubmit }) => {
  const [tenantName, setTenantName] = useState('');
  const [requestorEmail, setRequestorEmail] = useState('');
  const [tenantType, setTenantType] = useState('');
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();

  const hasAdminRole = () => {
    const account = instance.getActiveAccount();
    if (!account) return false;
    
    const idTokenClaims = account.idTokenClaims as { roles?: string[] };
    const userRoles = idTokenClaims?.roles || [];
    return userRoles.includes('DemoTenantMgmtAdmin');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true
    try {
        // Prepare tenant data
        const tenantData: TenantFormData = { 
            tenantName, 
            tenantType,
            ...(hasAdminRole() && { requestorEmail })
          };

        // Call the API to add a tenant with the tenant data
        //const newTenants = await addTenant(tenantData); // Pass the tenant data
  
        // Call the function passed from TenantManager to update the tenant list
        await onSubmit(tenantData);
        // Reset form fields
        setTenantName('');
        setRequestorEmail('');
        setTenantType('');
    } catch (error) {
        console.error('Failed to add tenant:', error);
    } finally {
        setLoading(false); // Set loading state to false
    }
  };

  return (
    
        <form className="tenant-form" onSubmit={handleSubmit}>
            <input
                className="form-input"
                type="text"
                value={tenantName}
                onChange={(e) => setTenantName(e.target.value)}
                placeholder="Tenant Name"
                required
            />
            {hasAdminRole() && ( 
                <input
                    className="form-input"
                    type="email"
                    value={requestorEmail}
                    onChange={(e) => setRequestorEmail(e.target.value)}
                    placeholder="Requestor Email"
                    required
                />
            )}
            <select
            className="form-select"
            value={tenantType}
            onChange={(e) => setTenantType(e.target.value)}
            required
            >
                <option value="" disabled>Select Tenant Type</option>
                <option value="english">English</option>
                <option value="qra">QRA</option>
            </select>
            <button type="submit" className="btn" disabled={loading}>
              <div className="button-content">
                {loading ?
                  <div className="spinner-container">
                      <div className="spinner"></div>
                      Processing...
                  </div> 
                  : 'Create Tenant'}
              </div>
            </button>
        </form>
    
  );
};

export default TenantForm;
