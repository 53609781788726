import React, { useState, useEffect, useCallback } from 'react';
import AdminForm from './AdminForm';
import AdminTable from './AdminTable';
import { useAdminService, AdminTableItem } from '../services/adminService';

const AdminPage: React.FC = () => {
  const [adminItems, setAdminItems] = useState<AdminTableItem[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const adminService = useAdminService();

  useEffect(() => {
    const fetchUpgrades = async () => {
      try {
        const upgrades = await adminService.getUpgrades();
        setAdminItems(upgrades);
        setError(null);
      } catch (err) {
        setError('Failed to fetch upgrades. Please refresh the page.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUpgrades();
  }, [adminService]);

  function sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleSubmit = useCallback(async (data: { tenantType: string; version: string }) => {
    try {
      const newItem = await adminService.submitBackupUpgrade(data.tenantType, data.version);
      setAdminItems(prevItems => [newItem, ...prevItems]);
      //await sleep(5000);
      setError(null);
    } catch (err) {
      setError('Failed to submit backup/upgrade request. Please try again.');
    }
  }, [adminService]);

  const handleCheckStatus = useCallback(async (id: string, tenantType: string, version: string) => {
    try {
      const updatedItem = await adminService.checkStatus(id, tenantType, version);
      setAdminItems(prevItems => prevItems.map(item => 
        item.id === id ? updatedItem : item
      ));
      setError(null);
    } catch (err) {
      setError('Failed to check status. Please try again.');
    }
  }, [adminService]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="tenant-manager">
      <h1>Admin Page</h1>
      <div className="content-wrapper">
        <div className="form-container">
          <h2>Backup/Upgrade Tenant</h2>
          {error && <div className="error">{error}</div>}
          <AdminForm onSubmit={handleSubmit} />
        </div>
        <AdminTable items={adminItems} onCheckStatus={handleCheckStatus} />
      </div>
    </div>
  );
};

export default AdminPage;