import { useMsal } from "@azure/msal-react";
import { useCallback, useMemo } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const useAuthenticatedFetch = () => {
  const { instance, accounts } = useMsal();

  const getIdToken = useCallback(async () => {
    const account = accounts[0];
    if (!account) {
      throw new Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    try {
      const response = await instance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: account
      });
      return response.idToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenPopup({
          scopes: ["User.Read"]
        });
      } else {
        throw error;
      }
    }
  }, [instance, accounts]);

  return useMemo(() => async (input: RequestInfo, init?: RequestInit) => {
    const token = await getIdToken();
    const headers = new Headers(init?.headers);
    headers.set("Authorization", `Bearer ${token}`);

    return fetch(input, {
      ...init,
      headers,
    });
  }, [getIdToken]);
};