import React, { useState } from 'react';
import '../styles/DeleteModal.css';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  tenantName: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ isOpen, onClose, onConfirm, tenantName }) => {
    const [loading, setLoading] = useState(false);

    if (!isOpen) return null; 
  
    const handleConfirm = async () => {
        setLoading(true);
        await onConfirm();
        setLoading(false);
    };

    return (
        <div className="modal-overlay">
            <div className="modal">
                <p>Are you sure you want to delete tenant "{tenantName}"?</p>
                <div className="modal-buttons">
                    <button className="btn btn-danger" onClick={handleConfirm} disabled={loading}>
                        <div className="button-content">
                            {loading ? <div className="spinner"></div> : 'Yes, delete'}
                        </div>
                    </button>
                    <button className="btn btn-secondary" onClick={onClose} disabled={loading}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default DeleteModal;