import React, { useState } from 'react';

interface AdminFormData {
  tenantType: string;
  version: string;
}

interface AdminFormProps {
  onSubmit: (data: AdminFormData) => Promise<void>;
}

const AdminForm: React.FC<AdminFormProps> = ({ onSubmit }) => {
  const [tenantType, setTenantType] = useState('');
  const [version, setVersion] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const tenantTypes = ['english', 'qra'];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await onSubmit({ tenantType, version });
      setTenantType('');
      setVersion('');
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="tenant-form" onSubmit={handleSubmit}>
      <select
        className="form-select"
        value={tenantType}
        onChange={(e) => setTenantType(e.target.value)}
        required
        disabled={isLoading}
      >
        <option value="">Select Tenant Type</option>
        {tenantTypes.map((type) => (
          <option key={type} value={type}>
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </option>
        ))}
      </select>
      <input
        className="form-input"
        type="text"
        value={version}
        onChange={(e) => setVersion(e.target.value)}
        placeholder="Version"
        required
        disabled={isLoading}
      />
      <button className="btn fixed-width-btn" type="submit" disabled={isLoading}>
        {isLoading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
            Processing...
          </div>
        ) : (
          'Backup/Upgrade'
        )}
      </button>
    </form>
  );
};

export default AdminForm;
